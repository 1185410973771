import request from '@/utils/request'

//销售毛利   分页列表
export function saleGainAnalysisAPI (params) {
  return request({
    url: '/api/system/sale/analysis/saleGainAnalysis',
    method: 'get',
    params
  })
}

//销售毛利   合计列表
export function saleGainAnalysisSummaryAPI (params) {
  return request({
    url: '/api/system/sale/analysis/saleGainAnalysisSummary',
    method: 'get',
    params
  })
}